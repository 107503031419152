<template src="./einsteinConectaRoom.html" />

<script>
import { computed } from "vue"
////////////////////// Chat texto //////////////////////////////////////////////

import { Chat } from "vue-quick-chat";
import "vue-quick-chat/dist/vue-quick-chat.css";
// import router from "@/router/router";

import { useStore } from "@/store/store.js"
import mixins from "@/mixins/mixins.js";

export default {
  name: "EinsteinConecta",
  mixins: [mixins],
  components: {
    Chat,
  },
  data() {
    return {
      // einsteinUrl: "",
      log: "",
      showIconCam: true,
      cardRtc: true,
      specialty: "",
      crmNumber: "",
      roomUrl: "",
      //    chat room    //
      accessCam: false,
      accessMic: false,
      isChrome: undefined,
      isWkWebView: false,
      popupCamChrome: false,
      supportsTouch: undefined,
      videoEnabled: true,
      audioEnabled: true,
      //                 //
      ////////////////////// Chat texto //////////////////////////////////////////////
      visible: true,
      participants: [
        {
          name: "Profissional da saúde",
          id: null,
          profilePicture: null,
        },
      ],
      myself: {
        name: "Paciente",
        id: null,
        profilePicture: null,
      },
      messages: [],
      chatTitle: "Sala de atendimento",
      placeholder: "Escreva e envie sua mensagem",
      colors: {
        header: {
          bg: "#FF9F43",
          text: "#fff",
        },
        message: {
          myself: {
            bg: "#fff",
            text: "#bdb8b8",
          },
          others: {
            bg: "#FF9F43",
            text: "#fff",
          },
          messagesDisplay: {
            bg: "#f7f3f3",
          },
        },
        submitIcon: "#E56B1F",
        submitImageIcon: "#FF9F43",
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      hideCloseButton: true,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      asyncMode: false,
      toLoad: [],
      scrollBottom: {
        messageSent: true,
        messageReceived: false,
      },
      displayHeader: true,
      profilePictureConfig: {
        others: false,
        myself: false,
        styles: {
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        },
      },
      timestampConfig: {
        format: "HH:mm",
        relative: false,
      },
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
      linkOptions: {
        myself: {
          className: "myLinkClass",
          events: {
            click: function () {
              alert("Link clicked!");
            },
            mouseover: function () {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
        others: {
          className: "othersLinkClass",
          events: {
            click: function () {
              alert("Link clicked!");
            },
            mouseover: function () {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
      },
    };
  },
  setup () {
    const store = useStore()

    return {
      getEinsteinUrl: () => store.dispatch("getEinsteinUrl"),
      einsteinUrl: computed(() => store.getters.einsteinUrl),
    }    
  },
  mounted () {
    this.init()
  },
  methods: {
    ios() {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.einsteinMessageHandler) {
        const msg = this.einsteinUrl;
        window.webkit.messageHandlers.einsteinMessageHandler.postMessage(
          msg
        );
      }
    },
    init() {
      this.$vs.loading()
      this.askForCredentials()
    },
    async askForCredentials() {
      await this.getEinsteinUrl()

      if (!this.einsteinUrl) {        
        this.$emit("showModal", "modal-wait-24h")
      } else {
        document.getElementById("einsteinConecta").href = this.einsteinUrl

        if (window.webkit && window.webkit.messageHandlers) {
          this.ios();
        } else {
          const newWin = window.open(this.einsteinUrl, "_blank")

          if(!newWin || newWin.closed || typeof newWin.closed === "undefined") {
            console.log("popup_blocked")
          }
        }
      }
      
      this.$vs.loading.close()
    },
  },
};
</script>

<style lang="scss" src="./einsteinConectaRoom.scss"></style>
